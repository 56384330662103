export type ValidationFnArgs = { value: string; isTouched: boolean; errorMessage: string };
export type ShowErrorFnArgs = { isValid: boolean; isTouched: boolean; errorMessage: string };

export const defaultValidationFn = (_args: ValidationFnArgs) => true;
export const defaultShowErrorFn = ({ isValid, isTouched, errorMessage }: ShowErrorFnArgs) =>
	(!isValid && isTouched) || !!errorMessage;

const useInputField = ({
	initialValue = "",
	initialErrorMessage = "",
	initialTouched = false,
	validationFn = defaultValidationFn,
	checkShowErrorFn = defaultShowErrorFn
}: {
	initialValue?: string;
	initialErrorMessage?: string;
	initialTouched?: boolean;
	validationFn?: (args: ValidationFnArgs) => boolean | string;
	checkShowErrorFn?: (args: ShowErrorFnArgs) => boolean;
}) => {
	const value = ref(initialValue);
	const isTouched = ref(initialTouched);
	const errorMessage = ref(initialErrorMessage);
	const isValid = computed(() => {
		const valid = validationFn({ value: value.value, isTouched: isTouched.value, errorMessage: errorMessage.value });
		return typeof valid === "string" ? false : valid;
	});
	const errorType = computed(() => {
		const valid = validationFn({ value: value.value, isTouched: isTouched.value, errorMessage: errorMessage.value });
		return typeof valid === "string" ? valid : "";
	});
	const isShowError = computed(() =>
		checkShowErrorFn({ isTouched: isTouched.value, isValid: !!isValid.value, errorMessage: errorMessage.value })
	);

	return {
		value,
		isTouched,
		isValid,
		errorType,
		isShowError,
		errorMessage
	};
};

export default useInputField;
